import React, {useContext, useState, createContext, useEffect} from 'react'
import { useApolloClient } from '@apollo/client'
import {useStore} from 'contexts/StoreProvider'
import {OPTION_GROUPS, ADD_OPTION_GROUP, UPDATE_OPTION_GROUP, DELETE_OPTION_GROUP, SORT_OPTION_GROUP} from 'query'

const OptionGroupContext = createContext(null);

const OptionGroupProvider = ({children}) => 
{
  const apolloClient = useApolloClient();
  const {selectedStoreID} = useStore()
  const [optionGroupLists, setOptionGroupLists] = useState([])

  useEffect (() => {
    if(selectedStoreID){
      getOptionGroupList(selectedStoreID)
    }
  },[selectedStoreID])

  const getOptionGroupList = async(store_id) => {
    try{
      if(store_id === null || store_id ===''){
        throw 'store_id cannot be empry or null'
      }
      let result = await apolloClient.query({
        query: OPTION_GROUPS,
        variables: {store_id:store_id},
      })

      const { result: data = [] } = result?.data?.optionGroups || {};

      const values = data?.map(item => {
        const min_select = parseInt(item.min_select)
        const max_select = parseInt(item.max_select)
        return {...item, min_select, max_select}
      })

      values?.sort((a, b) => a.sort - b.sort)
      
      setOptionGroupLists(values)
    } catch (err){
      console.error("OptionGroupProvider getOptionGroupList", err)
      throw err
    }
  }

  const sortOptionGroupList = async(data, completeData) => {
    try{
      let result = await apolloClient.mutate({
        mutation: SORT_OPTION_GROUP,
        variables: {input: data},
      })
      const { status } = result.data.sortOptionGroup

      if(status === 'success') {
        insertWithNewData('sort', null, completeData)
      }
      else {
        throw result
      }
    } catch (err){
      console.error("OptionGroupProvider sortOptionGroupList", err)
      throw err
    }
  }

  const createOption = async(input) => {
    try{
      let result = await apolloClient.mutate({
        mutation: ADD_OPTION_GROUP,
        variables: { input },
      })
      const { status, payload } = result.data.addOptionGroup
      
      if(status === 'success') {
        insertWithNewData('create', null, payload)
      }
      else {
        throw result
      }
    } catch (err){
      console.error("OptionGroupProvider createOption", err)
      throw err
    }
  }

  const updateOption = async(input, index) => {
    try{
      let result = await apolloClient.mutate({
        mutation: UPDATE_OPTION_GROUP,
        variables: { input },
      })
      const { status, payload } = result.data.updateOptionGroup

      if(status === 'success') insertWithNewData('update', index, payload)
      if(status !== 'success') throw result
      return result.data.updateOptionGroup;
    } catch (err){
      console.error("OptionGroupProvider updateOption", err)
      throw err
    }
  }

  const deleteOption = async (_id) => {
    try{
      let result = await apolloClient.mutate({
        mutation: DELETE_OPTION_GROUP,
        variables: {_id},
      })
      const { status } = result.data.deleteOptionGroup

      if(status === 'success') {
        insertWithNewData("delete", null, {_id})
      }
      else {
        throw result
      }
    } catch (err){
      console.error("OptionGroupProvider deleteOption", err)
      throw err
    }
  }

  const insertWithNewData = (type, idx, data) => {
    let values = [...optionGroupLists];

    if(type === 'sort') {
      values = data
    }

    if(type === "create") {
      values = [...values, data]
    }

    if(type === "update") {
      values[idx] = data
    }

    if(type === "delete") {
      values = values.filter((item) => item._id !== data['_id'])
    }

    setOptionGroupLists(values);
  }

  return (
    <OptionGroupContext.Provider
      value={{
        optionGroupLists,
        sortOptionGroupList,
        createOption,
        updateOption,
        deleteOption
      }}>
      {children}
    </OptionGroupContext.Provider>
  );
};

const useOptionGroups = () => {
  const contx = useContext(OptionGroupContext);
  if (contx == null) {
    throw new Error('useOptionGroups() called outside of a OptionGroupProvider?');
  }
  return contx;
};

export {OptionGroupProvider, useOptionGroups};


