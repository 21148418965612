import { gql } from '@apollo/client';

export const FEEDBACK_ATTRIBUTES = gql`
  fragment StandardFeedback on StandardFeedback {
    status
    msg
    payload
  }
`

export const BANKS = gql`
  query{
    banks{
      _id
      bank_name
      bic_code
      min_account_char
      max_account_char
    }
  }
`;
