import React, {useContext, useState, createContext} from 'react'
import { useApolloClient } from '@apollo/client'
import {useStore} from 'contexts/StoreProvider'
import {ITEM_REPORT} from 'query'

const ItemsReportContext = createContext(null);

const ItemsReportProvider = ({children}) => {
  const apolloClient = useApolloClient();
  const {selectedStoreID} = useStore()
  const [itemsReport, setItemsReport] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getItemsReport = async(store_id, from_date, to_date) => {
    if(store_id === null || store_id ===''){
      return
    }
    try{
      setIsLoading(true)
      let result = await apolloClient.query({
        query: ITEM_REPORT,
        variables: { store_id, from_date, to_date, },
      })
      setIsLoading(false)
      setItemsReport(result.data.itemsReport)
      return result.data.itemsReport
    } catch (err){
      setIsLoading(false)
      console.error("ItemsReportProvider getItemsReport", err)
      throw err
    }
  
  }


  return (
    <ItemsReportContext.Provider
      value={{
        itemsReport,
        getItemsReport,
        selectedStoreID,
        isLoading
      }}>
      {children}
    </ItemsReportContext.Provider>
  );
};

const useItemsReport = () => {
  const contx = useContext(ItemsReportContext);
  if (contx == null) {
    throw new Error('useItemsReport() called outside of a ItemsReportProvider?');
  }
  return contx;
};

export {ItemsReportProvider, useItemsReport};


