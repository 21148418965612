import React, {useContext, useState, createContext} from 'react'
import { useApolloClient } from '@apollo/client'
import {PAYMENT_REPORT} from 'query'

const PaymentReportContext = createContext(null);

const PaymentReportProvider = ({children}) => {
  const apolloClient = useApolloClient();
  const [report, setReport] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  //payment_type=="SPAY" || payment_type=="BOOST"
  const getReport = async(from_date, to_date, payment_type) => {
    try{
      setIsLoading(true)
      let result = await apolloClient.query({
        fetchPolicy: "network-only",
        query: PAYMENT_REPORT,
        variables: {
          from_date:from_date,
          to_date,
          payment_type:payment_type
        },
      })
      setReport(result.data.paymentReport)
      setIsLoading(false)
      return result.data.paymentReport
  
    } catch (err){
      setIsLoading(false)
      console.error("PaymentReportProvider getReport", err)
      throw err
    }
  
  }

  return (
    <PaymentReportContext.Provider
      value={{
        report,
        getReport,
        isLoading
      }}>
      {children}
    </PaymentReportContext.Provider>
  );
};

const usePaymentReport = () => {
  const contx = useContext(PaymentReportContext);
  if (contx == null) {
    throw new Error('usePaymentReport() called outside of a PaymentReportProvider?');
  }
  return contx;
};

export {PaymentReportProvider, usePaymentReport};


