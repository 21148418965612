import { gql } from '@apollo/client';
import {FEEDBACK_ATTRIBUTES} from './general'

const PRODUCTS_ATTRIBUTES = gql`
  fragment productAttributes on Product {
    _id
    name
    sku_name
    sku_number
    brand
    description
    price
    qty
    categories
    options
    status
    sort
    tags
    main_image
    images,
    created_date
    updated_date
    store_id
  }
`

export const PRODUCTS = gql`
  query($store_id: ID){
    products(store_id:$store_id){
      totalResult
      result {
        ...productAttributes
      }
      
    }
  }${PRODUCTS_ATTRIBUTES}
`;

export const SORT_PRODUCT_LIST = gql`
  mutation sortProductList($input:ProductSortInput){
    sortProductList(input:$input){
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`;

export const LITE_ADD_PRODUCT = gql`
  mutation liteAddProduct($input:LiteAddProductInput){
    liteAddProduct(input:$input){
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`;

export const ADD_PRODUCT = gql`
  mutation addProduct($input:ProductInput){
    addProduct(input:$input){
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($input:ProductInput){
    updateProduct(input:$input){
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($_id:ID){
    deleteProduct(_id:$_id){
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`;

export const CHANGE_PRODUCT_STATUS = gql`
  mutation ($_id: ID, $status:Int){
    changeProductStatus(_id:$_id, status:$status) {
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`;
