import React, {useContext, useState, createContext, useEffect} from 'react'
import { useApolloClient } from '@apollo/client'
import {useClientUser} from 'contexts/ClientUserProvider'
import {UPDATE_COMPANY, COMPANIES, LITE_ADD_COMPANY} from 'query'

const CompanyContext = createContext(null);

const CompanyProvider = ({children}) => {
  const apolloClient = useApolloClient();
  const {clientUserData} = useClientUser()
  const [companies, setCompanies] = useState([])
  
  useEffect (() => {
    getCompanyList()
  }, [clientUserData])

  const getCompanyList = async() => {
    try{
      let result = await apolloClient.query({
        fetchPolicy: "network-only",
        query: COMPANIES,
      })
      setCompanies(result.data.companies)
    } catch (err){
      console.error("CompanyProvider getCompanyList", err)
      throw err
    }

  }

  const liteAddCompany = async(input) => {
    try{
      const result = await apolloClient.mutate({
        mutation: LITE_ADD_COMPANY,
        variables: { input },
      })

      return result?.data?.liteAddCompany || null;
    } catch (err){
      console.error("CompanyProvider liteAddCompany", err)
      throw err
    }
  }

  const updateCompany = async(index, input) => {
    try{
      let result = await apolloClient.mutate({
        mutation: UPDATE_COMPANY,
        variables: {input},
      })
      const { status } = result.data.updateCompany

      if(status === 'success') {
        insertWithNewData('update', index, input);
      }
      
      else {
        throw result
      }
    }catch(err){
      console.error("CompanyProvider updateCompany", err)
      throw err
    }

  }

  const deleteCompany = async(id) => {
    // await getCompanyList();
  }

  const insertWithNewData = (type, index, data) => {
    let values = [...companies];
    
    if(type === 'update') {
      values[index] = data
    }

    setCompanies(values);
  }

  return (
    <CompanyContext.Provider
      value={{
        companies,
        liteAddCompany,
        updateCompany,
        deleteCompany
      }}>
      {children}
    </CompanyContext.Provider>
  );
};

const useCompany = () => {
  const Company = useContext(CompanyContext);
  if (Company == null) {
    throw new Error('useCompany() called outside of a CompanyProvider?');
  }
  return Company;
};

export {CompanyProvider, useCompany};