import { gql } from '@apollo/client';

const CLIENT_USER_ATTRIBUTES = gql`
  fragment clientUserAttributes on User {
    _id
      email
      name
      first_name
      last_name
      mobile
      stores{
        _id
        display_name
        store_name
        #company_id
        companies{
          _id
          permissions
        }
        email
        tel
        whatsapp
        website_url
        description
        logo_image
        # images
        status
        currency_id
        #name
        #order_status
        store_company_reg_no
        # last_bill_number
        # isOpen
        # operationType
        permissions
      }
      companies{
        _id
        display_name
        permissions
      }
      created_date
      updated_date
  }
`

export const user = gql`
  query {
    ...clientUserAttributes
  }${CLIENT_USER_ATTRIBUTES}
`;

export const CURRENT_USER = gql`
  query {
    currentUser{
      ...clientUserAttributes
    }
  }${CLIENT_USER_ATTRIBUTES}
`;

export const SIGN_IN = gql`
  mutation signIn($email: String!, $password: String!){
    signIn(email:$email,password:$password){
      _id
      token
      ...clientUserAttributes
    }
  }${CLIENT_USER_ATTRIBUTES}
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($password: String!){
    changePassword(password:$password){
      _id
    }
  }
`;

export const LITE_ADD_CLIENT_USER = gql`
  mutation liteAddClientUser($input: LiteAddClientUserInput){
    liteAddClientUser(input:$input){
      status
      msg
      payload
    }
  }
`;