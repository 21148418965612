import React, {useContext, useState, createContext, useEffect} from 'react'
import { useApolloClient } from '@apollo/client'
import {useStore} from 'contexts/StoreProvider'
import {CATEGORIES, ADD_CATEGORY, DELETE_CATEGORY,UPDATE_CATEGORY} from 'query'

const CategoryContext = createContext(null);

const CategoryProvider = ({children}) => {

  const apolloClient = useApolloClient();
  const { selectedStoreID } = useStore()
  const [categoryLists, setCategoryLists] = useState([])

  useEffect (() => {
    if(selectedStoreID){
      getCategoryList(selectedStoreID)
    }
  },[selectedStoreID])

  const getCategoryList = async(store_id) => {
    try{
      if(store_id === null || store_id ===''){
        throw 'store_id cannot be empty or null'
      }
      let result = await apolloClient.query({
        query: CATEGORIES,
        variables: {store_id:store_id},
      })

      const { result: data = [] } = result?.data?.categories || {};

      const values = data.map((item, index) => ({...item, sort: index}))

      values?.sort((a, b) => a.sort - b.sort)

      setCategoryLists(values)
    } catch (err){
      console.log("CategoryProvider createCategory", err)
      throw err
    }
  }

  const createCategory = async (input) => {
    try{
      let result = await apolloClient.mutate({
        mutation: ADD_CATEGORY,
        variables: { input },
      })
      const { status, payload } = result.data.addCategory
      
      if(status === 'success') insertWithNewData('create', null, payload)
      if(status !== 'success') throw result
      return result.data.addCategory;
    }catch(err){
      console.log("CategoryProvider createCategory", err)
      throw err
    }
  }

  const updateCategoryList = async (input) => {
    try{
      let result = await apolloClient.mutate({
        mutation: UPDATE_CATEGORY,
        variables: {input},
      })
      const { status } = result.data.updateCategory

      if(status === 'success') return true
      if(status !== 'success') throw result    
    }catch(err){
      console.log("CategoryProvider updateCategoryList", err)
      throw err
    }
  }

  const deleteCategory = async (_id) => {
    try{
      let result = await apolloClient.mutate({
        mutation: DELETE_CATEGORY,
        variables: {_id},
      })
      const { status } = result.data.deleteCategory

      if(status === 'success') insertWithNewData('delete', null, {_id})
      if(status !== 'success') throw result
    }catch(err){
      console.log("CategoryProvider deleteCategory", err)
      throw err
    }
  }

  const insertWithNewData = (type, idx, data) => {
    let values = [...categoryLists]

    if(type === 'create') {
      values = [...values, data]
    }

    if(type === 'delete') {
      values = values.filter(item => item._id !== data._id)
    }

    setCategoryLists(values)
  }

  return (
    <CategoryContext.Provider
      value={{
        categoryLists,
        createCategory,
        updateCategoryList,
        deleteCategory
      }}>
      {children}
    </CategoryContext.Provider>
  );
};

const useCategory = () => {
  const contx = useContext(CategoryContext);
  if (contx == null) {
    throw new Error('useCategory() called outside of a CategoryProvider?');
  }
  return contx;
};

export {CategoryProvider, useCategory};


