import { gql } from '@apollo/client';

const ORDERS_ATTRIBUTES = gql`
  fragment orderAttributes on Order {
    _id
    # qrCode
    customer
    store_id
    store_name
    status
    custom_table_no
    table_no
    payment_type
    payment_status
    payment_ref_no
    order_type
    order_type_label
    total_price
    products
    bill_no
    created_date
    operationType
  }
`

export const SALES_REPORT = gql`
  query ($store_id: ID, $from_date: String, $to_date:String){
    salesReport(store_id: $store_id, from_date:$from_date, to_date:$to_date){
      totalResult
      result{
        ...orderAttributes
      }
    }
  }${ORDERS_ATTRIBUTES}
`;

export const ITEM_REPORT = gql`
  query ($store_id: ID, $from_date: String, $to_date:String){
    itemsReport(store_id: $store_id, from_date:$from_date, to_date:$to_date)
  }
`;


