import { gql } from '@apollo/client';

// const PAYMENT_REPORT_ATTRIBUTES = gql`
//   fragment paymentReportAttributes on JSON {
//     _id
//     qrCode
//     customer
//     store_id
//     store_name
//     status
//     custom_table_no
//     payment_type
//     payment_status
//     order_type
//     order_type_label
//     total_price
//     products
//     bill_no
//     created_date
//     operationType
//   }
// `

export const PAYMENT_REPORT = gql`
  query ($from_date: String, $to_date:String, $payment_type:String){
    paymentReport(from_date:$from_date, to_date:$to_date, payment_type: $payment_type)
  }
`;




