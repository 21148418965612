import { gql } from '@apollo/client';
import {FEEDBACK_ATTRIBUTES} from './general'

const CATEGORIES_ATTRIBUTES = gql`
  fragment categorytAttributes on Category {
    _id
    name
    display_name
    sort
    store_id
    status
  }
`

export const CATEGORIES = gql`
  query($store_id: ID){
    categories(store_id:$store_id){
      totalResult
      result {
        ...categorytAttributes
      }
      
    }
  }${CATEGORIES_ATTRIBUTES}
`;

export const ADD_CATEGORY = gql`
  mutation addCategory($input: CategoryInput){
    addCategory(input:$input){
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($_id:ID){
    deleteCategory(_id:$_id){
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory($input:[CategoryInput]){
    updateCategory(input:$input){
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`;
