import React from 'react'
import { render } from 'react-dom';
import { Provider } from 'react-redux'
import { CompanyProvider} from 'contexts/CompanyProvider'
import { ClientUserProvider } from './contexts/ClientUserProvider'
import { StoreProvider } from './contexts/StoreProvider'
import {CategoryProvider} from 'contexts/CategoryProvider'
import {OptionGroupProvider} from 'contexts/OptionGroupProvider'
import { ProductProvider } from 'contexts/ProductProvider'
import { SalesReportProvider } from 'contexts/SalesReportProvider'
import { ItemsReportProvider } from 'contexts/ItemsReportProvider'
import { PaymentReportProvider } from 'contexts/PaymentReportProvider'
import { QrCodeProvider } from 'contexts/QrCodeProvider';
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from './lib/apollo'
import App from './App'
import store from './store'
import { icons } from './assets/icons'

import 'assets/locale/i18n'
import 'core-js'
import './scss/style.scss';
import 'react-phone-input-2/lib/style.css'

React.icons = icons

const container = document.getElementById('root');

render(
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <ClientUserProvider>
        <CompanyProvider>
          <StoreProvider>
            <CategoryProvider>
              <OptionGroupProvider>
                <ProductProvider>
                  <QrCodeProvider>
                    <SalesReportProvider>
                      <PaymentReportProvider>
                        <ItemsReportProvider>
                          <App />
                        </ItemsReportProvider>
                      </PaymentReportProvider>
                    </SalesReportProvider>
                  </QrCodeProvider>
                </ProductProvider>
              </OptionGroupProvider>
            </CategoryProvider>
          </StoreProvider>
        </CompanyProvider>
      </ClientUserProvider>
    </ApolloProvider>
  </Provider>
, container);